<template>
    <div class="pagebox">

        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 公告内容 -->
        <div class="noticecon">
            <div class="tit">湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知</div>
            <div class="time">2023-08-25 14:30</div>
            <div class="txt">湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知<br/><br/><p>湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知湖北省高等学校招生委员会关于做好2023年普通高校招生工作的通知</p></div>
        </div>

        <!-- 底部 -->
        <PFooter></PFooter>

    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
export default{
    components:{
        PHeader,
        PFooter
    },
    data(){
        return{
            date:'',
            txt:''
        }
    },
    mounted(){
        
    },
    methods:{
        
    }
}
</script>
<style lang="less" scoped>
.noticecon{
    width: 800px;
    margin: 30px auto 30px;
    border: 1px solid #ddd;
    min-height: 600px;
    background-color: #fff;
    .tit{
        color: #666;
        font-size: 18px;
        line-height: 30px;
        margin: 20px 20px 0;
        text-align: center;
    }
    .time{
        text-align: center;
        color: #666;
        font-size: 14px;
        margin-top: 10px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
    }
    .txt{
        color: #666;
        font-size: 14px;
        line-height: 24px;
        text-indent: 28px;
        margin: 20px 20px 30px;
        overflow: hidden;
    }
}
</style>